import React, { useEffect, useRef } from "react";
import { AuthData, UserType } from "../types/airgraft";
import { useSetState } from "react-use";

const stub = (): never => {
  throw new Error(
    "You forgot to wrap your component in <AuthenticationProvider>."
  );
};

interface UserContextType {

  email: string;

  /**
   * Current api token inside localstorage
   */
  token: string | null;

  /**
   * Current sign-in user object
   */
  user: UserType | null;

  login: (email: string) => void;

  /**
   * Sign out user and clear token in local storage
   */
  logout: (completion?: (() => void)) => void;
}

export const AuthenticationContext = React.createContext<UserContextType>({
  email: "",
  token: null,
  user: null,
  login: stub,
  logout: stub,
});

const initState = {
  email: window.localStorage.getItem("kEmail") ?? "",
  token: "fake",
  user: null,
};

var setStateCompletionCallback: (() => void) | undefined

export default function AuthenticationProvider({ children }: { children: any }) {
  
  const [state, setState] = useSetState<AuthData>(initState);

  const isFirstTime = useRef(true);
  useEffect(() => {
    if (isFirstTime.current) {
      isFirstTime.current = false
    } else {
      window.localStorage.setItem("kEmail", state.email)
      setStateCompletionCallback && setStateCompletionCallback()
      setStateCompletionCallback = undefined
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const login = (email: string) => {
    setState({
      ...state,
      email
    })
  }

  const logout = (completion?: (() => void)) => {
    setStateCompletionCallback = completion
    setState({ email: "", user: null, token: null });
  };

  return (
    <AuthenticationContext.Provider
      value={{
        email: state.email,
        token: state.token,
        user: state.user,
        login,
        logout,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}
