import React from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { styled, Container } from "@material-ui/core";
import Navigator from "./navigator";
import MultiStepNavigation from "../multistep-navigation";
import useConfig from "../../hooks/use-config";
import Loading from "../Loading";

const CustomContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: '100vw',
  minWidth: '320px',
  minHeight: '240px',
  flex: '1 0 auto',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  [theme.breakpoints.up("sm")]: {
    maxWidth: '600px',
  },
  [theme.breakpoints.down(375)]: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
}));

type Props = {
  title?: string;
  showBack?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  leftTitle?: string;
  rightTitle?: string;
  leftPath?: string;
  rightPath?: string;
  leftAction?: (() => void);
  rightAction?: (() => void);
  currStep?: number;
  children: any;
  shouldRenderLoading?: boolean;
};

export default function Layout({
  title, showBack,
  leftIcon, rightIcon,
  leftTitle, rightTitle,
  leftPath, rightPath,
  leftAction, rightAction,
  currStep,
  children,
  shouldRenderLoading,
}: Props) {
  const { tt } = useConfig();
  return tt.length === 0 ?
  (
    <Loading />
  ) : (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      height="100vh"
      bgcolor="background"
    >
      {currStep === undefined && (
        <Navigator
          title={title}
          showBack={showBack}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          leftTitle={leftTitle}
          rightTitle={rightTitle}
          leftPath={leftPath}
          rightPath={rightPath}
          leftAction={leftAction}
          rightAction={rightAction} />
      )}

      {currStep !== undefined && (
        <MultiStepNavigation
          activeStepIndex={currStep}
          showBack={showBack}
          leftPath={leftPath}
          rightPath={rightPath}
          steps={["YOUR OIL", "CONFIGURE DUPLICATOR", "SUMMARY"]}
        />
      )}

      <CustomContainer>
        {shouldRenderLoading && (
          <Box display="flex" justifyContent="center" marginTop={8}>
            <CircularProgress size={40} />
          </Box>
        )}
        {!shouldRenderLoading && children}
      </CustomContainer>
    </Box>
  )
}
