import React from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Theme
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import useSetting from "../hooks/use-setting";
import { OilHeatSettingsData } from "../types/airgraft";
import Layout from "../components/layouts";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import CustomSlider from "../components/CustomSlider"
import useXSmallWidthMediaQuery from "../hooks/use-xsmall-width-mq";
import useXSmallHeightMediaQuery from "../hooks/use-xsmall-height-mq";
import { Helmet } from "react-helmet";
import { addLog } from "../services/logs";
import useConfig from "../hooks/use-config";
import { useEffectOnce } from "react-use";
import useAuth from "../hooks/use-auth";
import { isValidEmail } from "../helpers/validateEmail";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 400,
    fontFamily: "Reckless",
    fontSize: "20px",
    lineHeight: 1.2
  },
  content: {
    fontWeight: 400,
    fontFamily: "Roobert",
    fontSize: "14px",
    lineHeight: 1.25
  },
  rowsContainer: {
    display: "flex",
    flexWrap: "wrap"
  },
  fullRow: {
    flexBasis: "100%"
  },
  halfRowLeft: {
    flexBasis: "50%",
    paddingRight: theme.spacing(1)
  },
  halfRowRight: {
    flexBasis: "50%",
    paddingLeft: theme.spacing(1)
  },
  footer: {
    fontSize: '0.8rem'
  },
  fillHeight: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flex: 1,
    justifyContent: "center"
  },
  saveButton: {
    fontWeight: 600,
    fontFamily: "Roobert",
    fontSize: "12px",
    letterSpacing: "1px",
    height: 36,
    textAlign: "center",
    borderRadius: "50px",
    textTransform: "uppercase",
    color: "#000",
    backgroundColor: "#FFF",
    "&:hover": {
      background: "#DDD",
    }
  },
  card: {
    borderRadius: "5px",
    background: "#FFFFFF",
  },
  cardTitle: {
    fontWeight: 400,
    fontFamily: "Reckless",
    fontSize: "18px",
    letterSpacing: "0px"
  },
  cardContent: {
    fontWeight: 400,
    fontFamily: "Roobert",
    fontSize: "11px",
    letterSpacing: "0px"
  },
  slider: {
    paddingLeft: "4px",
    paddingRight: "4px",
  },
  sliderTitle: {
    fontWeight: 600,
    fontSize: 14,
    letterSpacing: "1.5px",
    textTransform: "uppercase",
    textAlign: "right",
  },
  sliderFooter: {
    fontWeight: 400,
    fontSize: 13,
    letterSpacing: "1.5px",
    textTransform: "uppercase",
  },
  justifyContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between"
  },
  bottomBar: {
    position: "fixed",
    left: "0",
    right: "0",
    bottom: "0",
    transform: "translate(calc(50vw - 50%))",
    maxWidth: '100vw',
    minWidth: '320px',
    [theme.breakpoints.up("sm")]: {
      maxWidth: '600px',
    },
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down(375)]: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    display: "flex",
    flexWrap: "wrap",
  },
  bottomBarBackground: {
    borderTop: "1px solid #DDD",
    backgroundColor: "#F3F0E9"
  },
}));

function OilPage() {
  const classes = useStyles();
  const isXSmallWidth = useXSmallWidthMediaQuery();
  const isXSmallHeight = useXSmallHeightMediaQuery();

  const { isNew, id, brand, oilName, oilType, oilStrain, cannabinoid, terpenes, htStep, hpStep, ppStep, cfStep, isLocked, update, sharedUrl, importFromLocalStorage, exportToLocalStorage } = useSetting();
  const history = useHistory();
  const { ht, hp, pp, cf, ul, uh } = useConfig();
  const { email } = useAuth();

  const isAdvMode = process.env.REACT_APP_ENV === "adv";

  useEffectOnce(() => {
    if (email === undefined || !isValidEmail(email)) {
      history.push("/auth")
      return
    } 
    // Load from local storage to avoid loosing data after refreshing
    importFromLocalStorage((data) => {
      if (data === undefined) {
        history.push("/auth")
        return
      }
      const { id } = data
      if (id === null) {
        history.push("/user")
        return
      }
    })
  })

  const { handleSubmit } = useForm<{}>();

  const handleFormSubmit = async () => {
    const isAdvMode = process.env.REACT_APP_ENV === "adv";
    const htValue = isAdvMode ? htStep : ht[htStep]
    const hpValue = isAdvMode ? hpStep : hp[hpStep]
    const ppValue = isAdvMode ? ppStep : pp[ppStep]
    const cfValue = isAdvMode ? cfStep : cf[cfStep]
    addLog(
      id,
      email,
      brand,
      oilName,
      oilType,
      oilStrain,
      `${cannabinoid?.toFixed(0) ?? 0}%`,
      `${terpenes?.toFixed(0) ?? 0}%`,
      `${htStep + 1}=${(1 + 0.1 * htValue).toFixed(1)}s(${htValue})`,
      `${hpStep + 1}=${(7.25 + 0.25 * hpValue).toFixed(2)}w(${hpValue})`,
      `${ppStep + 1}=${5 + 5 * ppValue}%(${ppValue})`,
      `${cfStep + 1}=${(0.1 + 0.1 * cfValue).toFixed(1)}(${cfValue})`,
      `${window.location.origin}${sharedUrl()}`,
      isNew ? "init" : "update",
      isLocked ? "1" : ""
    )
    {
      let isNew = false;
      update({ isNew } as OilHeatSettingsData)
    }
    exportToLocalStorage()
    history.push(`/configure`);
  };

  return (
    <Layout title="Edit Oil Profile"
            showBack={true}>
      <Helmet>
        <title>Oil</title>
      </Helmet>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Box className={classes.rowsContainer}>
          <Box mt={isXSmallHeight ? 1 : 2}>
            <Typography className={classes.title}>
              Let's start with your oil
            </Typography>
          </Box>
          <Box mt={isXSmallHeight ? 1 : 2} className={classes.fullRow}>
            <TextField
              id="brand"
              name="brand"
              label="Brand"
              value={brand ?? ""}
              InputLabelProps={{
                style: { fontSize: isXSmallWidth ? "0.8rem" : "0.9rem" },
                shrink: true
              }}
              placeholder="e.g. Friendly Farms"
              fullWidth
              size="small"
              inputProps={{ style: { fontSize: isXSmallWidth ? "0.8rem" : "0.9rem" } }}
              autoComplete="brand"
              onChange={ (e) => {
                const brand = e.target.value as string | null
                update({ brand } as OilHeatSettingsData)
              }}
            />
          </Box>
          <Box mt={isXSmallHeight ? 1 : 2} className={classes.fullRow}>
            <TextField
              id="oilName"
              name="oilName"
              label="Oil Name"
              defaultValue={oilName}
              InputLabelProps={{
                style: { fontSize: isXSmallWidth ? "0.8rem" : "0.9rem" },
                shrink: true
              }}
              placeholder="e.g. Lemonade"
              fullWidth
              size="small"
              inputProps={{ style: { fontSize: isXSmallWidth ? "0.8rem" : "0.9rem" } }}
              autoComplete="oilName"
              onChange={ (e) => {
                const oilName = e.target.value as string | null
                update({ oilName } as OilHeatSettingsData)
              }}
            />
          </Box>
          <Box mt={isXSmallHeight ? 1 : 2} className={classes.fullRow}>
            <TextField
              id="oilType"
              name="oilType"
              label="Oil Type"
              defaultValue={oilType}
              InputLabelProps={{
                style: { fontSize: isXSmallWidth ? "0.8rem" : "0.9rem" },
                shrink: true
              }}
              placeholder="e.g. Live Resin"
              fullWidth
              size="small"
              inputProps={{ style: { fontSize: isXSmallWidth ? "0.8rem" : "0.9rem" } }}
              autoComplete="oilType"
              onChange={ (e) => {
                const oilType = e.target.value as string | null
                update({ oilType } as OilHeatSettingsData)
              }}
            />
          </Box>
          <Box mt={isXSmallHeight ? 1 : 2} className={classes.fullRow}>
            <TextField
              id="oilStrain"
              name="oilStrain"
              label="Oil Strain"
              defaultValue={oilStrain}
              InputLabelProps={{
                style: { fontSize: isXSmallWidth ? "0.9rem" : "1.0rem" },
                shrink: true
              }}
              placeholder="e.g. Sativa"
              fullWidth
              size="small"
              inputProps={{ style: { fontSize: isXSmallWidth ? "0.8rem" : "0.9rem" } }}
              autoComplete="oilStrain"
              onChange={ (e) => {
                const oilStrain = e.target.value as string | null
                update({ oilStrain } as OilHeatSettingsData)
              }}
            />
          </Box>
          { isAdvMode ? <Box /> : (
            <Box mt={isXSmallHeight ? 1 : 2}>
              <Typography className={classes.content}>
                The best heat settings for your oil dependes on its terpene % and cannabinoid %. Select the estimated values to start.
              </Typography>
            </Box>
          )}
        </Box>
        { isAdvMode ? <Box /> : (
          <Box mt={isXSmallHeight ? 1 : 2} className={classes.card} style={{ paddingTop: "5px", paddingBottom: "10px" }}>
            <Box ml={1} mr={1}>
              <Typography className={classes.cardTitle}>
                Target Cannabinoid %
              </Typography>
            </Box>
            <Box ml={1} mr={1}>
              <Box>
                <Box className={classes.justifyContainer}>
                  <Box/>
                  <Box>
                    <Typography className={classes.sliderTitle} align="right">
                      {cannabinoid ?? 0 ?? "?"}%
                    </Typography>
                  </Box>
                </Box>
                <Box mt={isXSmallHeight ? 0 : 0.5} mb={isXSmallHeight ? 0 : 1} className={classes.slider}>
                  <CustomSlider
                    type='y2r'
                    value={cannabinoid ?? 0}
                    min={0}
                    max={100}
                    step={5}
                    setValue={(newValue) => {
                      const cannabinoid = newValue as number
                      const unknownIsHigh = (100 - cannabinoid - (terpenes ?? 0)) >= 12
                      const defaultValues = unknownIsHigh ? uh : ul
                      const htStep = ht.indexOf(defaultValues[0])
                      const hpStep = hp.indexOf(defaultValues[1])
                      const ppStep = pp.indexOf(defaultValues[2])
                      const cfStep = cf.indexOf(defaultValues[3])
                      update({ cannabinoid, htStep, hpStep, ppStep, cfStep } as OilHeatSettingsData)
                  }}/>
                </Box>
                <Box className={classes.justifyContainer}>
                  <Box>
                    <Typography variant="body2" className={classes.sliderFooter}>
                      lower
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2" align="right" className={classes.sliderFooter}>
                      higher
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        { isAdvMode ? <Box /> : (
          <Box mt={isXSmallHeight ? 1 : 2} className={classes.card} style={{ paddingTop: "5px", paddingBottom: "10px" }}>
            <Box ml={1} mr={1}>
              <Typography className={classes.cardTitle}>
                Terpene %
              </Typography>
              <Typography className={classes.cardContent}>
                Currently supports terpene levels between 0-20%. If your oil is out of this range, please contact Airgraft for custom settings.
              </Typography>
            </Box>
            <Box ml={1} mr={1}>
              <Box>
                <Box className={classes.justifyContainer}>
                  <Box/>
                  <Box>
                    <Typography className={classes.sliderTitle} align="right">
                      {terpenes ?? 0 ?? "?"}%
                    </Typography>
                  </Box>
                </Box>
                <Box mt={isXSmallHeight ? 0 : 0.5} mb={isXSmallHeight ? 0 : 1} className={classes.slider}>
                  <CustomSlider
                    type='y2r'
                    value={terpenes ?? 0}
                    min={0}
                    max={20}
                    step={1}
                    setValue={(newValue) => {
                      const terpenes = newValue as number
                      const unknownIsHigh = (100 - (cannabinoid ?? 0) - terpenes) >= 12
                      const defaultValues = unknownIsHigh ? uh : ul
                      const htStep = ht.indexOf(defaultValues[0])
                      const hpStep = hp.indexOf(defaultValues[1])
                      const ppStep = pp.indexOf(defaultValues[2])
                      const cfStep = cf.indexOf(defaultValues[3])
                      update({ terpenes, htStep, hpStep, ppStep, cfStep } as OilHeatSettingsData)
                  }}/>
                </Box>
                <Box className={classes.justifyContainer}>
                  <Box>
                    <Typography variant="body2" className={classes.sliderFooter}>
                      lower
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2" align="right" className={classes.sliderFooter}>
                      higher
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        <Box height={isXSmallHeight ? 64 : 88} />
        <Box height={isXSmallHeight ? 52 : 68} className={`${classes.bottomBarBackground} ${classes.bottomBar}`} />
        <Box className={classes.bottomBar} mt={isXSmallHeight ? 1 : 2} mb={isXSmallHeight ? 1 : 2}>
          <Button
            variant="contained"
            className={classes.saveButton}
            disableElevation
            disableFocusRipple
            disableRipple
            fullWidth
            type="submit"
          >
            save & start configuring
          </Button>
        </Box>
      </form>
    </Layout>
  );
}

export default OilPage;