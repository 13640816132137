import React from "react";
import {
  Box,
  IconButton,
  useTheme
} from "@material-ui/core";
import Progressbar from "./components/Progessbar";
import ChevronLeft from "../icons/chevron-left";
import ChevronRight from "../icons/chevron-right";
import MultistepNavigationLabel from "./components/NavigationLabel";
import { Link } from "react-router-dom";
import useXSmallWidthMediaQuery from "../../hooks/use-xsmall-width-mq";

type Props = {
  steps: string[];
  activeStepIndex: number;
  showBack?: boolean;
  leftPath?: string;
  rightPath?: string;
};

const MultiStepNavigation = React.memo(
  ({ activeStepIndex, steps, showBack, leftPath, rightPath }: Props) => {
    const theme = useTheme();
    const isXSmallWidth = useXSmallWidthMediaQuery();

    return (
      <>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="stretch"
          width="100%"
          height="var(--header-height)"
          flexShrink={0}
          bgcolor="background.paper"
          zIndex={1000}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingX={isXSmallWidth ? 0 : 1}
            height="calc(100% - 3px)"
          >
            <Box width={theme.spacing(6)}>
              {showBack && (
                <IconButton
                  color="secondary"
                  aria-label="back"
                  data-testid="multistep-navigation.back"
                  onClick={() => window.history.back()}
                >
                  <ChevronLeft />
                </IconButton>
              )}
              {leftPath && (
                <IconButton
                  color="secondary"
                  aria-label="back"
                  data-testid="multistep-navigation.back"
                  component={Link}
                  to={leftPath}
                >
                  <ChevronLeft />
                </IconButton>
              )}
            </Box>

            <MultistepNavigationLabel
              steps={steps}
              activeStepIndex={activeStepIndex}
            />

            <Box width={theme.spacing(6)}>
              {rightPath && (
                <IconButton
                  color="secondary"
                  aria-label="next"
                  data-testid="multistep-navigation.next"
                  component={Link}
                  to={rightPath}
                >
                  <ChevronRight />
                </IconButton>
              )}
            </Box>
          </Box>

          <Progressbar
            activeStepIndex={activeStepIndex}
            totalStepsCount={steps.length}
          />
        </Box>
      </>
    );
  }
);

export default MultiStepNavigation;
