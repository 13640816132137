import React, { Component, ErrorInfo } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

interface OwnProps {}

interface OwnState {
  eventId?: string | null;
  hasError?: boolean;
  children?: any;
}

class ExceptionHandler extends Component<OwnProps, OwnState> {
  constructor(props: OwnProps) {
    super(props);
    this.state = {
      eventId: null,
      hasError: false
    } as OwnState;
  }

  // FIXME: GetDerivedStateFromError<P, S> https://stackoverflow.com/questions/55946119/what-error-type-can-we-expect-in-getderivedstatefromerror
  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(err: Error, errorInfo: ErrorInfo) {
    console.error("Error Boundary Caught:", err);
  }

  render() {
    const { hasError } = this.state;

    const handleClose = () => {
      this.setState({ hasError: false });
    };

    return (
      <React.Fragment>
        <Dialog
          //@ts-ignore
          open={hasError}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Oops</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Something went wrong, we're working on a fix
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              Okay
            </Button>
          </DialogActions>
        </Dialog>
        {this.props.children}
      </React.Fragment>
    );
  }
}

export default ExceptionHandler;
