import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import AuthPage from "../pages/AuthPage";
import UserProfilePage from "../pages/UserProfilePage";
import OilPage from "../pages/OilPage";
import ConfigurePage from "../pages/ConfigurePage";
import ActivationPage from "../pages/ActivationPage";
import OilProfilePage from "../pages/OilProfilePage";
import LockedOilProfilesPage from "../pages/LockedOilProfilesPage";

export default function Routers() {
  return (
    <Router>
      <Switch>
        <Route path="/auth">
          <AuthPage />
        </Route>
        <Route path="/oil">
          <OilPage />
        </Route>
        <Route path="/configure">
          <ConfigurePage />
        </Route>
        <Route path="/user">
          <UserProfilePage />
        </Route>
        <Route path="/locked">
          <LockedOilProfilesPage />
        </Route>
        <Route path="/profile">
          <OilProfilePage />
        </Route>
        <Route path="/activation">
          <ActivationPage />
        </Route>
        <Redirect from="/*" to="/user" />
      </Switch>
    </Router>
  );
}
