import * as React from "react";
import { CssBaseline } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import grey from "@material-ui/core/colors/grey";
import RoobertRegularWoff2 from "../assets/fonts/roobert-regular-webfont.woff2";
import RoobertRegularWoff from "../assets/fonts/roobert-regular-webfont.woff";
import RoobertRegularTrueType from "../assets/fonts/roobert-regular-webfont.ttf";
import RecklessLightWoff2 from "../assets/fonts/reckless-light-webfont.woff2";
import RecklessLightWoff from "../assets/fonts/reckless-light-webfont.woff";
import RecklessLightTrueType from "../assets/fonts/reckless-light-webfont.ttf";
import RoobertTrialLightWoff2 from "../assets/fonts/rooberttrial-light-webfont.woff2";
import RoobertTrialLightWoff from "../assets/fonts/rooberttrial-light-webfont.woff";
import RoobertTrialLightTrueType from "../assets/fonts/rooberttrial-light-webfont.ttf";

const roobert = {
  fontFamily: "Roobert",
  src: `url(${RoobertRegularWoff2}) format("woff2"),url(${RoobertRegularWoff}) format("woff"), url(${RoobertRegularTrueType}) format("truetype")`,
  fontWeight: 400,
  fontStyle: "normal"
};

const reckless = {
  fontFamily: "Reckless",
  src: `url(${RecklessLightWoff2}) format("woff2"),url(${RecklessLightWoff}) format("woff"), url(${RecklessLightTrueType}) format("truetype")`,
  fontWeight: 400,
  fontStyle: "normal"
};
const roobertTrial = {
  fontFamily: "Roobert TRIAL",
  src: `url(${RoobertTrialLightWoff2}) format("woff2"),url(${RoobertTrialLightWoff}) format("woff"), url(${RoobertTrialLightTrueType}) format("truetype")`,
  fontWeight: 400,
  fontStyle: "normal"
};

const defaultTheme = createTheme({});

// @ts-ignore
const theme = createTheme({
  palette: {
    primary: {
      light: "#FFF94F",
      main: "#FFBB00",
      dark: "#C79600"
    },
    secondary: grey,
    background: {
      default: "#F3F0E9",
      paper: "#F9F7F1"
    }
  },
  shape: {
    borderRadius: defaultTheme.spacing(0.25)
  },
  typography: {
    fontSize: 14,
    fontFamily: ["Roobert", "Reckless", "Roobert TRIAL"].join(","),
    h1: {
      fontWeight: 400,
      fontFamily: "Reckless",
      lineHeight: 2.7,
      letterSpacing: "1px",
      fontSize: "2.28rem"
    },
    h2: {
      fontWeight: 400,
      fontFamily: "Reckless",
      lineHeight: 2.28,
      letterSpacing: "1px",
      fontSize: "1.85rem"
    },
    h3: {
      fontWeight: 400,
      fontFamily: "Reckless",
      lineHeight: 2.1,
      letterSpacing: "0.5px",
      fontSize: "1.71rem"
    },
    h4: {
      fontWeight: 400,
      fontFamily: "Reckless",
      lineHeight: 1.9,
      letterSpacing: "0.5px",
      fontSize: "1.42rem"
    },
    h5: {
      fontWeight: 400,
      fontFamily: "Reckless",
      lineHeight: 1.85,
      letterSpacing: "0.5px",
      fontSize: "1.28rem"
    },
    h6: {
      fontWeight: 400,
      fontFamily: "Reckless",
      lineHeight: 1.5,
      letterSpacing: "0.75px",
      fontSize: "1rem"
    },
    body1: {
      fontWeight: 400,
      fontFamily: "Roobert",
      lineHeight: 1.5,
      letterSpacing: "0.75px",
      fontSize: "1rem"
    },
    body2: {
      fontWeight: 400,
      fontFamily: "Roobert",
      lineHeight: 1.42,
      letterSpacing: "0.5px",
      fontSize: "0.85rem"
    },
    caption: {
      fontWeight: 400,
      fontFamily: "Roobert",
      lineHeight: 1.42,
      letterSpacing: "0.75px",
      fontSize: "0.71rem"
    },
    button: {
      fontWeight: 400,
      fontFamily: "Roobert",
      lineHeight: 1.42,
      letterSpacing: "0.5px",
      fontSize: "0.85rem"
    },
    overline: {
      fontWeight: 400,
      fontFamily: "Roobert",
      lineHeight: 1.42,
      letterSpacing: "0.75px",
      fontSize: "0.71rem"
    }
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        ":root": {
          "--header-height": "64px",
          "--subheader-height": "56px",
          "--mobile-bottom-navigation-height": "0px",
          [defaultTheme.breakpoints.down("sm")]: {
            "--mobile-bottom-navigation-height": "64px"
          },
          [defaultTheme.breakpoints.down("xs")]: {
            "--header-height": "50px"
          }
        },
        "*": {
          "-webkit-font-smoothing": "antialiased",
          "-moz-osx-font-smoothing": "grayscale",
          "box-sizing": "border-box",
          "&::before, &::after": {
            "box-sizing": "border-box"
          }
        },
        "@font-face": [roobert, reckless, roobertTrial],
        // Using global styles because SnackbarProvider is parent of the theme provider
        ".MuiSnackbar-root": {
          // @ts-ignore
          "&.MuiSnackbar-anchorOriginTopCenter": {
            top: "16px"
          },
          "& .MuiSnackbarContent-root": {
            backgroundColor: defaultTheme.palette.common.white,
            color: defaultTheme.palette.common.black,
            fontFamily: "Roobert",
            fontSize: "1.6rem",
            fontWeight: 500,
            lineHeight: "2rem",
            boxShadow: "0px 0px 4px rgba(30, 30, 30, 0.2)",
            borderRadius: "3px",
            padding: "8px 16px"
          }
        },
        ".MuiSnackbar-root .MuiSnackbarContent-root": {
          // Using global styles because SnackbarProvider is parent of the theme provider
          backgroundColor: defaultTheme.palette.common.white,
          color: defaultTheme.palette.common.black,
          fontSize: "1.4rem"
        },
      }
    },
    MuiInputBase: {
      root: {
        fontFamily: "Roobert TRIAL"
      }
    },
    MuiTable: {
      root: {
        position: "relative"
        // tableLayout: "fixed"
      }
    },
    MuiTableCell: {
      root: {
        padding: defaultTheme.spacing(1)
      },
      head: {
        borderBottom: `3px solid #000000`
        // fontWeight: defaultTheme.typography.fontWeightBold
      }
    },
    MuiTablePagination: {
      root: {},
      caption: {
        paddingBottom: defaultTheme.spacing(0.5)
      },
      select: {},
      selectIcon: {
        top: 0
      }
    },
    MuiTableSortLabel: {
      icon: {
        height: defaultTheme.spacing(2),
        width: defaultTheme.spacing(2)
      }
    },
    MuiDialogContent: {
      root: {
        overflowY: "unset"
      }
    },
    MuiIconButton: {
      root: {
        color: defaultTheme.palette.common.black
      }
    }
  }
});

interface WrappedComponentProps {}

function AirgraftTheme<P extends WrappedComponentProps>(
  Component: React.ComponentType<P>
) {
  function AirgraftTheme(props: P) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...props} />
      </ThemeProvider>
    );
  }

  return AirgraftTheme;
}

export default AirgraftTheme;
