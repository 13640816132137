import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useTheme 
} from "@material-ui/core";
import React from "react";
import AirgraftLogo from "../../assets/logo.svg";
import ChevronLeft from "../icons/chevron-left";
import ChevronRight from "../icons/chevron-right";
import { Link } from "react-router-dom";
import useXSmallWidthMediaQuery from "../../hooks/use-xsmall-width-mq";

type Props = {
  title?: string;
  showBack?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  leftTitle?: string;
  rightTitle?: string;
  leftPath?: string;
  rightPath?: string;
  leftAction?: (() => void);
  rightAction?: (() => void);
};

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 400,
    fontSize: "14px",
    textTransform: "uppercase",
    letterSpacing: "1.5px",
    fontFamily: "Roobert",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  actionTitle: {
    fontWeight: 400,
    fontSize: "12px",
    fontFamily: "Roobert",
    textTransform: "none"
  },
}));

export default function Navigator({
  title, showBack,
  leftIcon, rightIcon,
  leftTitle, rightTitle,
  leftPath, rightPath,
  leftAction, rightAction
}: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const isXSmallWidth = useXSmallWidthMediaQuery();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      width="100%"
      height="var(--header-height)"
      bgcolor="background.paper"
      zIndex={1000}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingX={isXSmallWidth ? 0 : 1}
        height="calc(100% - 3px)"
      >
        <Box width={theme.spacing(7)}>
          {showBack && (
            <IconButton
              color="secondary"
              aria-label="back"
              data-testid="multistep-navigation.back"
              onClick={() => window.history.back()}
            >
              <ChevronLeft />
            </IconButton>
          )}
          {leftTitle && (
            <Button className={classes.actionTitle} onClick={leftAction} fullWidth>
              {leftTitle}
            </Button>
          )}
          {leftPath && (
            <IconButton
              color="secondary"
              aria-label="back"
              data-testid="multistep-navigation.back"
              component={Link}
              to={leftPath}
            >
              {leftIcon ? leftIcon : <ChevronLeft />}
            </IconButton>
          )}
        </Box>
        <Box display="flex" height="var(--header-height)">
          {title ? 
            <Typography className={classes.title}>{title}</Typography> :
            <img src={AirgraftLogo} alt="Airgraft Logo" title="Airgraft" width="180" />
          }
        </Box>
        <Box width={theme.spacing(7)}>
          {rightTitle && (
            <Button className={classes.actionTitle} onClick={rightAction} fullWidth>
              {rightTitle}
            </Button>
          )}
          {rightPath && (
            <IconButton
              color="secondary"
              aria-label="next"
              data-testid="multistep-navigation.next"
              component={Link}
              to={rightPath}
            >
              {rightIcon ? rightIcon : <ChevronRight />}
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  );
}
