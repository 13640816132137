import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export default function ChevronRight(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 22 20" {...props} style={{ fill: "none" }}>
      <path
        d="M8 3L13 10L8 17"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
