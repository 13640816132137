import { useEffect } from "react";

export default function usePreventTouchMove() {
  useEffect(() => {
    document.ontouchmove = function(event) {
      event.preventDefault();
    };
    return () => {
      document.ontouchmove = null;
    };
  }, []);
}
