import React from "react";
import { StylesProvider } from "@material-ui/styles";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import Loading from "./components/Loading";
import ExceptionHandler from "./components/ExceptionHandler";
import Routers from "./routers";
import AirgraftTheme from "./components/AirgraftTheme";
import { SnackbarProvider } from "material-ui-snackbar-provider";
import AuthenticationProvider from "./providers/authentication";
import SettingProvider from "./providers/setting";
import ConfigProvider from "./providers/config";

// @ts-ignore
const useGlobalStyles = makeStyles((theme: Theme) => ({
  "@global": {
    html: {
      width: "100%",
      height: "100%",
    },
    body: {
      display: "flex",
      flex: `1 0 auto`,
    },
    "#root": {
      display: "flex",
      flex: `1 0 auto`,
      justifyContent: "center",
    },
    a: {
      color: "inherit" /* blue colors for links too */,
      textDecoration: "inherit" /* no underline */,
    },
  },
}));

function AppBase() {
  useGlobalStyles();
  return (
    <StylesProvider>
      <React.Suspense fallback={<Loading />}>
        <SnackbarProvider
          SnackbarProps={{
            autoHideDuration: 1000,
            anchorOrigin: { vertical: "top", horizontal: "center" }
          }}
        >
          <AuthenticationProvider>
            <ConfigProvider>
              <SettingProvider>
                <ExceptionHandler>
                  <Routers />
                </ExceptionHandler>
              </SettingProvider>
            </ConfigProvider>
          </AuthenticationProvider>
        </SnackbarProvider>
      </React.Suspense>
    </StylesProvider>
  );
}

const App = AirgraftTheme(AppBase);

export default App;
