import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Theme
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import Layout from "../components/layouts";
import { makeStyles } from "@material-ui/styles";
import useXSmallWidthMediaQuery from "../hooks/use-xsmall-width-mq";
import useXSmallHeightMediaQuery from "../hooks/use-xsmall-height-mq";
import { Helmet } from "react-helmet";
import { useEffectOnce } from "react-use";
import { isValidEmail } from "../helpers/validateEmail";
import useAuth from "../hooks/use-auth";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 400,
    fontFamily: "Reckless",
    fontSize: "24px",
    lineHeight: 1.2
  },
  content: {
    fontWeight: 400,
    fontFamily: "Roobert",
    fontSize: "14px",
    lineHeight: 1.25
  },
  rowsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  fullRow: {
    flexBasis: "100%"
  },
  halfRowLeft: {
    flexBasis: "50%",
    paddingRight: theme.spacing(1)
  },
  halfRowRight: {
    flexBasis: "50%",
    paddingLeft: theme.spacing(1)
  },
  footer: {
    fontSize: '0.8rem'
  },
  fillHeight: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flex: 1,
    justifyContent: "center"
  },
  button: {
    fontWeight: 600,
    fontFamily: "Roobert",
    fontSize: "12px",
    letterSpacing: "1px",
    height: 36,
    textAlign: "center",
    borderRadius: "50px",
    textTransform: "uppercase"
  },
  card: {
    borderRadius: "5px",
    background: "#FFFFFF",
  },
  cardTitle: {
    fontWeight: 400,
    fontFamily: "Reckless",
    fontSize: "18px",
    letterSpacing: "0px"
  },
  cardContent: {
    fontWeight: 400,
    fontFamily: "Roobert",
    fontSize: "11px",
    letterSpacing: "0px"
  },
  slider: {
    paddingLeft: "4px",
    paddingRight: "4px",
  },
  sliderTitle: {
    fontWeight: 600,
    fontSize: 14,
    letterSpacing: "1.5px",
    textTransform: "uppercase",
    textAlign: "right",
  },
  sliderFooter: {
    fontWeight: 400,
    fontSize: 13,
    letterSpacing: "1.5px",
    textTransform: "uppercase",
  },
  justifyContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between"
  },
  bottomBar: {
    position: "fixed",
    left: "0",
    right: "0",
    bottom: "0",
    transform: "translate(calc(50vw - 50%))",
    maxWidth: '100vw',
    minWidth: '320px',
    [theme.breakpoints.up("sm")]: {
      maxWidth: '600px',
    },
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down(375)]: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    display: "flex",
    flexWrap: "wrap",
  },
  bottomBarBackground: {
    borderTop: "1px solid #DDD",
    backgroundColor: "#F3F0E9"
  },
}));

function AuthPage() {
  const classes = useStyles();
  const isXSmallWidth = useXSmallWidthMediaQuery();
  const isXSmallHeight = useXSmallHeightMediaQuery();
  const { email, login } = useAuth();
  const history = useHistory();
  const [isCTAEnabled, setIsCTAEnabled] = useState<boolean>(false);
  const [currEmail, setCurrEmail] = useState<string>("");

  useEffectOnce(() => {
    setCurrEmail(email)
  })

  useEffect(() => {
    // Enable CTA if email is valid
    udpateCTA(currEmail)
  })

  const { handleSubmit } = useForm<{}>();

  const handleFormSubmit = async () => {
    login(currEmail)
    history.push("/user");
  };

  const udpateCTA = (text: string | null | undefined) => {
    setIsCTAEnabled(isValidEmail(text))
  }

  return (
    <Layout>
      <Helmet>
        <title>Sign In</title>
      </Helmet>
      <form className={classes.fillHeight} onSubmit={handleSubmit(handleFormSubmit)}>
        <Box className={classes.rowsContainer}>
          <Box>
            <Typography className={classes.title}>
              Welcome!
            </Typography>
          </Box>
          <Box mt={isXSmallHeight ? 2 : 4} className={classes.fullRow}>
            <TextField
              id="email"
              name="email"
              label="Email"
              value={currEmail}
              InputLabelProps={{
                style: { fontSize: isXSmallWidth ? "0.8rem" : "0.9rem" },
                shrink: true
              }}
              placeholder="xyz@abc.com"
              fullWidth
              size="small"
              inputProps={{ style: { fontSize: isXSmallWidth ? "0.8rem" : "0.9rem" } }}
              autoComplete="email"
              onChange={ (e) => {
                var email = e.target.value as string | null ?? ""
                email = email.toLowerCase()
                setCurrEmail(email)
              }}
            />
          </Box>
          <Box mt={isXSmallHeight ? 2 : 4} className={classes.fullRow}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              disableElevation
              disableFocusRipple
              disableRipple
              fullWidth
              type="submit"
              disabled={!isCTAEnabled}
            >
              SIGN IN
            </Button>
          </Box>
          <Box height={160} />
        </Box>
      </form>
    </Layout>
  );
}

export default AuthPage;