import isFloat from "validator/es/lib/isFloat";
import { OilHeatSettingsData } from "../types/airgraft";

export function validateQueryParams(
  data: any
): OilHeatSettingsData | false {
  let {
    id,
    bd: brand,
    on: oilName,
    ot: oilType,
    os: oilStrain,
    cbd: cannabinoid,
    tp: terpenes,
    ht: htStep,
    hp: hpStep,
    pp: ppStep,
    cf: cfStep,
    lock: isLocked
  } = data;
  // TODO: validate number range
  let isNew = false;
  if (!cannabinoid || !isFloat(cannabinoid)) return false;
  if (!terpenes || !isFloat(terpenes)) return false;
  if (!htStep || !isFloat(htStep)) return false;
  if (!hpStep || !isFloat(hpStep)) return false;
  if (!ppStep || !isFloat(ppStep)) return false;
  if (!cfStep || !isFloat(cfStep)) return false;
  cannabinoid = parseFloat(cannabinoid);
  terpenes = parseFloat(terpenes);
  htStep = parseInt(htStep);
  hpStep = parseInt(hpStep);
  ppStep = parseInt(ppStep);
  cfStep = parseInt(cfStep);
  console.log("import", data)

  return {
    isNew,
    id,
    brand,
    oilName,
    oilType,
    oilStrain,
    cannabinoid,
    terpenes,
    htStep,
    hpStep,
    ppStep,
    cfStep,
    isLocked
  };
}