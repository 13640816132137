import { useMediaQuery } from "@material-ui/core";

export const XSMALL_WIDTH_BREAKPOINT = 374;
export const XSMALL_WIDTH_BREAKPOINT_MQ = `@media (max-width: ${XSMALL_WIDTH_BREAKPOINT}px)`;

/**
 * Detect if the current viewport width is extra small (Under 375)
 */
export default function useXSmallWidthMediaQuery() {
  return useMediaQuery(`(max-width:${XSMALL_WIDTH_BREAKPOINT}px)`);
}