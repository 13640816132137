import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import usePreventTouchMove from "../../hooks/use-prevent-touch-move";
import { PodActivationDataType } from "./types.d";

type Props = {
  podActivation: PodActivationDataType;
  onComplete: () => void;
};

export default function ActivationSequence({
  podActivation,
  onComplete,
}: Props) {
  usePreventTouchMove();
  const [sequenceIndex, setSequenceIndex] = useState<number>(0);

  // Loop throught sequence color and timings to re-render the <svg> using a different color
  useEffect(() => {
    const sequenceItem = podActivation[sequenceIndex];
    setTimeout(() => {
      if (sequenceIndex < podActivation.length - 1) {
        setSequenceIndex(sequenceIndex + 1);
      } else {
        onComplete();
      }
    }, sequenceItem.time);
  }, [onComplete, podActivation, sequenceIndex]);

  return (
    <Box
      position={{ xs: "fixed", sm: "absolute" }}
      top={0}
      left={0}
      width="100%"
      height="100%"
      zIndex={9999}
      bgcolor="transparent"
      data-testid="activation.activation-sequence"
    >
      <svg width="100%" height="100%" fill="none">
        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          fill={podActivation[sequenceIndex].color}
        />
      </svg>
    </Box>
  );
}
