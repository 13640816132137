import React from "react";
import { styled, Typography } from "@material-ui/core";

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: "0.8rem",
  lineHeight: 1,
  textTransform: "uppercase",
  letterSpacing: "1px",
  fontFamily: "Roobert"
}));

function getMultistepNavigationLabel({
  currentStep,
  currentStepLabel,
  totalSteps
}: {
  currentStep: number;
  totalSteps: number;
  currentStepLabel: string;
}) {
  // prettier-ignore
  return `Step ${currentStep} of ${totalSteps}${currentStepLabel ? ` / ${currentStepLabel}` : ""}`;
}


export default function MultistepNavigationLabel({
  steps,
  activeStepIndex
}: {
  steps: string[];
  activeStepIndex: number;
}) {
  return (
    <CustomTypography>
      {getMultistepNavigationLabel({
        currentStep: activeStepIndex + 1,
        totalSteps: steps.length,
        currentStepLabel: steps[activeStepIndex]
      })}
    </CustomTypography>
  );
}
